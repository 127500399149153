//@ts-check
import React from 'react'
import { uniq } from 'ramda'
import { Link, Navigate } from 'react-router-dom'
import useNewlyRegistered from 'hooks/useNewlyRegistered'
import { dateToString } from 'utils/date'
import Back from 'assets/icons/Back'
import SubjectSelect from 'components/selects/SubjectSelect'
import Calendar from 'components/calendars/Calendar'
import Button from 'components/buttons/Button'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import Modal from 'components/modals/Modal'
import { H2, H3, H4, H5, Paragraph } from 'components/typography'
import HelpModal from 'components/modals/HelpModal'
import { AcademicBackgroundOptions } from 'components/selects/AcademicBackgroundSelect'
import styles from './NewlyRegistered.module.css'

function NewlyRegistered() {
  const {
    state,
    form,
    student,
    filteredTeachers,
    subjectFilter,
    extractSubjectsById,
    handleChangeVisibleMonth,
    handleFormChange,
    checkDisabledDate,
    handleNext,
    handleBack,
    handleSelectTeacher,
    handleShowHelpModal,
    handleSelectSecondTestAttendance,
    handleCloseConfirmModal,
    handleSave,
    handleCloseHelpModal,
    handleConfirmShowSecondTestOptions,
    handleCloseSecondTestAlert,
    handleCloseSecondTestConfirmModal,
    handleSaveSecondTestAttendance
  } = useNewlyRegistered()

  if (!state.canBookAClass) return <Navigate to='/' />
  return (
    <section className={styles.section}>
      {state.step === 0 && (
        <InitialState
          student={student}
          form={form}
          filter={subjectFilter}
          extractSubjectsById={extractSubjectsById}
          isFetchingSchedules={state.isFetchingSchedules}
          isSpecialCase={
            (student.trial?.subjectIds || []).length === 0 ||
            [
              AcademicBackgroundOptions.OTROS,
              AcademicBackgroundOptions.UNIVERSIDAD
            ].includes(student.trial?.academicBackground)
          }
          hours={state.availableTeacherHours}
          onChangeVisibleMonth={handleChangeVisibleMonth}
          onChangeDate={handleFormChange}
          onChange={handleFormChange}
          checkDisabledDate={checkDisabledDate}
          onNext={handleNext}
          onHelp={handleShowHelpModal}
        />
      )}
      {state.step === 1 && (
        <Step1
          student={student}
          subjectsById={state.subjectsById}
          teachers={filteredTeachers}
          onSelectTeacher={handleSelectTeacher}
          onBack={handleBack}
        />
      )}
      {state.step === 2 && (
        <Step2
          form={form}
          student={student}
          subjectsById={state.subjectsById}
          teacher={state.teachers.find(({ id }) => id === form.teacherId)}
        />
      )}
      {state.step === 3 && (
        <Step3
          options={state.secondTestAttendanceOptions}
          trialSubjectIds={student.trial?.subjectIds}
          subjectsById={state.subjectsById}
          onSelect={handleSelectSecondTestAttendance}
        />
      )}
      {state.step === 4 && (
        <Step4
          selectedDate={form.selectedDate}
          startHour={form.startHour}
          studentName={student?.shortName}
          subjectName1={state.subjectsById[form.subjectId].name}
          subjectName2={state.subjectsById[state.selectedOption.subjectId].name}
          teacher1={state.teachers.find(({ id }) => id === form.teacherId)}
          teacher2={state.teachers.find(
            ({ id }) => id === state.selectedOption.teacherId
          )}
          subjectsById={state.subjectsById}
        />
      )}
      {state.showConfirmModal && (
        <Modal
          onCancel={handleCloseConfirmModal}
          onOk={handleSave}
          okDisabled={state.isSaving}
          okText={state.isSaving ? 'Guardando...' : undefined}
        >
          <div className={styles.modalContent}>
            <H2 className={styles.titleModal}>Confirmación</H2>

            <Paragraph className={styles.modalParagraph}>
              Estás a punto de agendar una clase de{' '}
              <strong>{state.subjectsById[form.subjectId]?.name}</strong> el{' '}
              <strong>{dateToString(form.selectedDate)}</strong> a las{' '}
              <strong>{form.startHour}</strong> con{' '}
              <strong>
                {state.teachers.find(({ id }) => id === form.teacherId)?.name}
              </strong>
            </Paragraph>
          </div>
        </Modal>
      )}
      {state.showHelpModal && <HelpModal onClose={handleCloseHelpModal} />}
      {state.showSecondTestAlert && (
        <Modal
          onOk={handleConfirmShowSecondTestOptions}
          onCancel={handleCloseSecondTestAlert}
          okText='Ver opciones'
          cancelText='No me interesa'
        >
          <div>
            <H2 className={styles.titleModal}>¡Espera!</H2>
            <Paragraph className={styles.modalParagraph}>
              Aún puedes elegir una segunda clase de prueba.
            </Paragraph>
          </div>
        </Modal>
      )}
      {state.showSecondTestConfirmModal && (
        <Modal
          onCancel={handleCloseSecondTestConfirmModal}
          onOk={handleSaveSecondTestAttendance}
          okDisabled={state.isSaving}
          okText={state.isSaving ? 'Guardando...' : undefined}
        >
          <div className={styles.modalContent}>
            <H2 className={styles.titleModal}>Confirmación</H2>

            <Paragraph className={styles.modalParagraph}>
              Estás a punto de agendar tu segunda clase de prueba de{' '}
              <strong>
                {state.subjectsById[state.selectedOption.subjectId]?.name}
              </strong>{' '}
              con{' '}
              <strong>
                {
                  state.secondTestAttendanceOptions.find(
                    ({ id }) => id === state.selectedOption.teacherId
                  )?.name
                }
              </strong>
            </Paragraph>
          </div>
        </Modal>
      )}
    </section>
  )
}

function InitialState({
  student,
  form,
  filter,
  extractSubjectsById,
  isFetchingSchedules,
  isSpecialCase,
  hours,
  checkDisabledDate,
  onChangeVisibleMonth,
  onChangeDate,
  onChange,
  onNext,
  onHelp
}) {
  return (
    <>
      <H2 className={styles.h2}>No problem {student?.shortName}!</H2>
      <H3 className={styles.h3}>{getText(form, isSpecialCase)}</H3>

      <SubjectSelect
        placeholder='Selecciona tu asignatura'
        showLabel={false}
        value={form.subjectId}
        filter={filter}
        extractSubjectsById={extractSubjectsById}
        onChange={onChange}
        customStyles={{ container: styles.customSelectContainer }}
        disabled={isSpecialCase}
      />
      <div className={styles.calendarWrapper}>
        <Calendar
          disabled={!form.subjectId || isFetchingSchedules}
          selectedDate={form.selectedDate}
          startHour={form.startHour}
          endHour={form.endHour}
          hours={hours}
          onChangeDate={onChangeDate}
          onChangeHour={onChange}
          onChangeVisibleMonth={onChangeVisibleMonth}
          customCheckDisabled={checkDisabledDate}
          disabledPast
        />
      </div>
      <div className={styles.toolbar}>
        <Button label='Ayuda' size='small' type='warning' onClick={onHelp} />
        <Button
          label='Agendar clase'
          size='small'
          disabled={!form.subjectId || !form.selectedDate || !form.startHour}
          onClick={onNext}
        />
      </div>
    </>
  )
}

function Step1({ student, teachers, subjectsById, onSelectTeacher, onBack }) {
  return (
    <>
      <div className={styles.step1TitleSection}>
        <Back
          color='var(--sandground)'
          className={styles.backIcon}
          onClick={onBack}
        />
        <H2 className={styles.h2}>¡Ya no queda nada {student?.shortName}!</H2>
      </div>

      <H3 className={styles.h3}>
        {' '}
        Tenemos estas opciones para tu <strong>clase de prueba.</strong>
        <br />
        <strong>Elige</strong> la que más te guste & <strong>Brain On!</strong>
      </H3>

      <div className={styles.teacherList}>
        {teachers?.map((teacher, index) => {
          return (
            <VerticalTecherCard
              key={index}
              teacher={teacher}
              subjectsById={subjectsById}
              onClick={() => onSelectTeacher(teacher.id)}
              buttonLabel='Agendar clase'
            />
          )
        })}
      </div>
    </>
  )
}

function Step2({ student, form, subjectsById, teacher }) {
  return (
    <div className={styles.step2}>
      <H2 className={styles.h2}>¡Enhorabuena {student?.shortName}!</H2>
      <H4 className={styles.h4}>
        El día <strong>{dateToString(form.selectedDate)}</strong>, a las{' '}
        <strong>{form.startHour}h</strong>, tienes tu clase de{' '}
        <strong>{subjectsById[form.subjectId]?.name}</strong> de prueba con tu
        profe <strong>{teacher?.name}.</strong>
      </H4>
      <div className={styles.step2SelectedTeacher}>
        <Link to='/'>
          <VerticalTecherCard
            teacher={teacher}
            subjectsById={subjectsById}
            date={form.selectedDate}
            startHour={form.startHour}
            buttonLabel='Ir al home'
          />
        </Link>
      </div>
    </div>
  )
}

function getText(form, isSpecialCase) {
  if (isSpecialCase)
    return (
      <>
        Nuestro <strong>equipo docente</strong> revisará tus necesidades y te
        contactaremos lo antes posible.
        <br />
        Estate atento al buzón de entrada de tu <strong>email</strong>.
        <br />
        Si quieres que te <strong>llamemos</strong> y contarnos más detalles,
        pulsa el botón de <strong>"Ayuda"</strong> y rellena los datos .
      </>
    )
  if (!form.subjectId)
    return (
      <>
        Elige tu <strong>asignatura</strong>, dinos qué día y hora te viene
        mejor
        <br />
        para agendar tu <strong>clase de prueba</strong>
      </>
    )
  return (
    <>
      Dinos qué día y hora te viene mejor
      <br />y agendamos tu <strong>clase de prueba</strong>
    </>
  )
}

function Step3({
  options = [],
  trialSubjectIds = [],
  subjectsById = {},
  onSelect
}) {
  const subjectIds = uniq(options.map(o => o.subjects).flat())
  return (
    <>
      <H2 className={styles.h2}>
        ¡Elige a tu próximo profe y sigue aprendiendo!{' '}
      </H2>
      <H3 className={styles.h3}>
        Puedes dar <strong>2</strong> clases de prueba en
        <strong> la misma hora</strong>. Elige tu segunda opción:
      </H3>

      {subjectIds
        .sort((a, b) => {
          const aInList = trialSubjectIds.includes(a)
          const bInList = trialSubjectIds.includes(b)
          if (aInList && !bInList) return -1
          if (!aInList && bInList) return 1
          return 0
        })
        .map(subjectId => {
          const teachers = options.filter(o => o.subjects.includes(subjectId))
          return (
            <>
              <H5 className={styles.step3H5}>
                {subjectsById[subjectId]?.label || '-'}
              </H5>
              <div className={styles.secondaryOptions}>
                {teachers.map(teacher => (
                  <VerticalTecherCard
                    classNames={{
                      container: styles.verticalTeacherContainer,
                      innerContainer: styles.verticalTeacherInnerContainer,
                      img: styles.verticalTeacherImg,
                      name: styles.verticalTeacherName,
                      vector: styles.verticalTeacherVector,
                      subjects: styles.verticalTeacherSubjects
                    }}
                    teacher={teacher}
                    buttonSize='tiny'
                    buttonLabel='Agendar'
                    onClick={() =>
                      onSelect({ teacherId: teacher.id, subjectId })
                    }
                  />
                ))}
              </div>
            </>
          )
        })}
    </>
  )
}

function Step4({
  studentName,
  selectedDate,
  startHour,
  subjectName1,
  subjectName2,
  subjectsById,
  teacher1,
  teacher2
}) {
  return (
    <div className={styles.step2}>
      <H2 className={styles.h2}>¡Enhorabuena {studentName}!</H2>
      <H4 className={styles.h4}>
        El día <strong>{dateToString(selectedDate)}</strong>, a las{' '}
        <strong>{startHour}h</strong>, podrás acceder a tus asignaturas de{' '}
        <strong>{subjectName1}</strong> y <strong>{subjectName2}</strong> con
        {teacher1.id === teacher2.id ? ' tu profe' : ' los profes'}:
      </H4>
      <div className={styles.step2SelectedTeacher}>
        <Link to='/'>
          <VerticalTecherCard
            teacher={teacher1}
            subjectsById={subjectsById}
            date={selectedDate}
            buttonLabel='Ir al home'
          />
        </Link>
        <Link to='/'>
          <VerticalTecherCard
            teacher={teacher2}
            subjectsById={subjectsById}
            date={selectedDate}
            buttonLabel='Ir al home'
          />
        </Link>
      </div>
    </div>
  )
}

export default NewlyRegistered
